img,
#sky h1,
#clouds,
#city,
#trees,
#ground {
    image-rendering: optimizeSpeed;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: pixelated;
}

@font-face {
    font-family: "Fipps";
    src: url("../assets/fonts/Fipps-Regular.ttf") format("truetype");
}

body {
    background-color: #311706;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3 {
    font-family: "Fipps";
}

#sky {
    background-color: #ebf6ff;
    padding-top: 60px;

    h1 {
        /* Hide the text and replace it with an image */
        background-image: url("../assets/menu/logo.png");
        background-repeat: no-repeat;
        background-size: 100%;
        width: 318px;
        height: 180px;
        text-overflow: clip;
        text-indent: -9999999px;
        margin: 0 auto;
    }
}

nav {
    width: 600px;
    margin: 20px auto;
    text-align: center;

    a {
        background-color: black;
        color: white;
        border-radius: 8px;
        line-height: 40px;
        width: 160px;
        margin: 10px;
        height: 36px;
        display: inline-block;
        text-align: center;
        font-family: "Fipps";
        text-decoration: none;
        &:hover {
            background-color: #72360e;
        }
    }
}

#call-to-action nav a {
    background-color: #be743f;
    color: black;

    &:hover {
        background-color: #e47f37;
    }
}

#io-acknowledgement {
    color: white;
    font-family: "Fipps";
    font-size: 12px;
    margin: 20px auto;
    text-align: center;
    width: 600px;

    a {
        color: white;

        &:hover {
            color: rgb(223, 164, 63);
        }
    }
}


nav a.disabled {
    opacity: 0.2;
    cursor: default;

    &:hover {
        background-color: black;
    }
}

#sky {
    #clouds {
        background-image: url("../assets/battle/themes/default/clouds.png");
        background-repeat: repeat-x;
        background-size: 296px 86px;
        height: 86px;
    }
    #city {
        background-image: url("../assets/battle/themes/default/city.png");
        background-repeat: repeat-x;
        background-size: 296px 58px;
        height: 58px;
        margin-top: -58px;
    }
    #trees {
        background-image: url("../assets/battle/themes/default/bushes.png");
        background-repeat: repeat-x;
        background-size: 296px 36px;
        height: 36px;
        margin-top: -36px;
    }
}

.container {
    width: 660px;
    margin: 0 auto;
    position: relative;
}

.large {
    width: auto;
    max-width: 800px;
    color: #fff;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    h2 {
        margin-top: 60px;
    }
}

.container a,
.large a {
    color: white;
    &:hover {
        color: rgb(223, 164, 63);
    }
}

#ground {
    background-image: url("../assets/battle/themes/default/ground.png"),
        url("../assets/battle/themes/default/ground.png");
    background-repeat: repeat-x, repeat-x;
    background-position-y: 0, 82px;
    /* 160 × 89 */
    background-size: 480px 267px;
    padding-top: 60px;
    padding-bottom: 21px;
    margin-bottom: -2px;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    #who,
    #what {
        font-family: Fipps;
        font-size: 16px;
        color: #ffffff;
        width: 320px;
    }
    #who span,
    #what span {
        display: block;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
            "Helvetica Neue", sans-serif;
        font-weight: bolder;
        font-size: 14px;
    }
}

h5 {
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    font-weight: bolder;
    font-size: 14px;
}

#ground {
    #who a,
    #what a {
        color: white;
    }
}

#underground {
    background: url("../assets/battle/themes/default/ground-under.png") repeat-x;
    /* 160 × 47 */
    background-size: 480px 141px;
    padding: 200px 0;
    color: white;

    h3 {
        margin-top: 30px;
        margin-bottom: 0px;
        font-size: 24px;
    }
    h5 {
        margin-top: 0px;
    }

    .thumbs {
        display: flex;
        a {
            flex: 1;
        }
    }
}

#stylish-flappers {
    margin-top: 128px;
}

ul.seats {
    padding: 0;
    margin-left: -20px;
    li {
        position: relative;
        width: 80px;
        height: 20px;
        display: inline-block;
        list-style: none;
        padding-top: 40px;
        img {
            position: absolute;
        }
    }
}

.seats li div {
    display: flex;
    align-items: center;
    justify-content: center;
}

#phone {
    position: absolute;
    right: 15px;
    top: 62px;
    width: 260px;
    height: 480px;
    background-image: url("./assets//phone-bg.png");
}

#projected {
    position: absolute;
    right: 3px;
    top: 60px;
    border: #be743f solid 2px;
    width: 249px;
}

#player {
    position: absolute;
    right: -54px;
    top: 422px;
}

#seating {
    background-image: url(./assets/projector.png);
    background-position: 160px 42px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
}

#call-to-action {
    margin-bottom: 90px;
    nav a {
        margin: 0 10px;
    }
}

.large {
    h5 {
        margin-bottom: 0;
    }
    h2 {
        font-size: 32px;
    }
}

.testimonial {
    margin-top: 180px;
    font-family: Fipps;
    font-size: 16px;
    color: #ffffff;

    li {
        list-style: none;
        margin-bottom: 40px;

        span {
            display: block;
            text-align: right;
            margin-top: -10px;
        }
    }
}

.news {
    ul {
        padding: 0;
    }

    img {
        width: 100%;
    }

    & > div > ul li {
        list-style: none;
    }
}
